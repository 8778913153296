import { Component, OnInit } from '@angular/core';
import { AccountServiceService } from '../account-service.service';
import {  Router} from '@angular/router';
import jwt_decode from "jwt-decode";
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  decoded:any
  errorMsg: string;
  public Username: string;
  Admin:any
  public Password: string;
  constructor(private accountService: AccountServiceService, private router: Router,private Toastr: ToastrService) { }

  ngOnInit() {
    // if(localStorage.getItem("access_token") != null){
    //   this.router.navigate(['/Dashboard']);
    // }
   
 


  }

  loginSubmit(){
    this.errorMsg = null;
    this.Toastr.toastrConfig.progressBar = true;
    this.accountService.login(this.Username,this.Password).subscribe((resp)=>{
      
    // Store access token, UserId and Role in local storage
    localStorage.setItem('access_token',resp.body.token);
    // localStorage.setItem('role',resp.Roles);
    var token = localStorage.getItem("access_token");
     this.decoded = jwt_decode(token);
           localStorage.setItem('role',this.decoded.Roles);

     if(localStorage.getItem("role")=='DTC' || localStorage.getItem("role")=='Admin' || localStorage.getItem("role")=='PM' ){
       
      this.router.navigate(['/Dashboard']);
      this.Toastr.success('Welcome to EngageAll DR TB Linkage' );

     }
     else{
      this.Toastr.error('Authorization Failed');
     }
     
    //  localStorage.setItem('role',this.decoded.Roles);
    //  localStorage.setItem('profileName',this.decoded.ProfileName);


 /*   this.accountService.startup().subscribe(data=>{
    },
    error=>{
      this.Toastr.error("Something went on the server side !!!");
      console.log(error);
    }); */

    // this.router.navigate(['/Dashboard']);
    // this.Toastr.success('Welcome To TTA', localStorage.getItem('role') );

    },
    (err)=>{
      if(err.status == 400){
        this.Toastr.error('Invalid Email Address or Password'); 
      }
      else{
        this.Toastr.error("Something went on the server side !!!");
      console.log(err);}
      
    });
  }
}
